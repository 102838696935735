<template>
  <!-- 学徒转师傅 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    />
    <div class="main-content">
      <div class="content-item" v-if="info">
        <div class="content-item-header">
          <div class="title">培训信息</div>
        </div>

        <div class="content-sub-title">
          <span>概括</span>
          <a-button type="link" @click="openModal">
            编辑讲师/带训
          </a-button>
        </div>

        <div class="ul">
          <div class="li">
            <span>理论讲师：</span>
            <span v-if="info.lectorName">{{ info.lectorName }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>实操讲师：</span>
            <span v-if="info.trainName">{{ info.trainName }}</span>
            <span v-else>-</span>
          </div>
          <div class="li">
            <span>带训师傅：</span>
            <span v-if="info.visitiservicer">{{ info.visitiservicer }}</span>
            <span v-else>-</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">
          <span>转编资料补充</span>
        </div>

        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 12 }"
        >
          <div class="form">
            <div class="item">
              <a-form-model-item label="师傅头像" prop="avatar">
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :action="uploadUrl"
                  :data="{
                    token: qiniuData.token
                  }"
                  :before-upload="beforeAvatarUpload"
                  @change="handleAvatarChange"
                >
                  <img
                    class="avatar"
                    :src="form.avatar"
                    alt="avatar"
                    v-if="form.avatar"
                  />
                  <div v-else>
                    <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </a-form-model-item>
              <a-form-model-item
                ref="jobNumber"
                label="师傅工号"
                prop="jobNumber"
              >
                <a-input
                  v-model="form.jobNumber"
                  placeholder="请输入师傅工号"
                  @blur="
                    () => {
                      $refs.jobNumber.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item
                ref="areaId"
                label="请选择所属地区"
                prop="areaId"
              >
                <a-cascader
                  :options="cascaderAreaOptions"
                  placeholder="请选择所属地区"
                  expand-trigger="hover"
                  :field-names="fieldNames"
                  v-model="form.areaId"
                />
              </a-form-model-item>
            </div>
            <a-divider type="vertical" />
            <div class="item">
              <a-form-model-item ref="level" label="设置师傅星级" prop="level">
                <a-select placeholder="选择星级" v-model="form.level">
                  <a-select-option
                    v-for="item in starData"
                    :key="item.id"
                    :value="item.level"
                  >
                    <a-rate disabled v-model="item.level" />
                    <span style="marginLeft: 12px" v-if="item.level === 1"
                      >实习期</span
                    >
                    <span style="marginLeft: 12px" v-else
                      >{{ item.level }}星</span
                    >
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                ref="trainNumber"
                label="培训合同编号"
                prop="trainNumber"
              >
                <a-input
                  v-model="form.trainNumber"
                  placeholder="请输入培训合同编号"
                  @blur="
                    () => {
                      $refs.trainNumber.onFieldBlur()
                    }
                  "
                />
              </a-form-model-item>

              <a-form-model-item
                prop="contract"
                label="合同扫描版（PDF）"
                extra="支持扩展名：.rar .zip .doc .docx .pdf .jpg..."
              >
                <a-upload
                  name="file"
                  :multiple="true"
                  :action="uploadUrl"
                  :data="{
                    token: qiniuData.token
                  }"
                  @change="handleContractChange"
                >
                  <a-button> <a-icon type="upload" /> 上传合同 </a-button>
                </a-upload>
              </a-form-model-item>
            </div>
          </div>
          <a-button
            class="trun-submit-btn"
            type="primary"
            @click="onSubmit"
            :loading="submitLoading"
          >
            确认转编
          </a-button>
        </a-form-model>
      </div>
    </div>

    <a-modal
      v-model="modalVisible"
      :title="title + ' - 讲师/带训'"
      :footer="false"
    >
      <a-form-model
        ref="modalRuleForm"
        :model="modalForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="理论讲师">
          <a-select v-model="modalForm.lectorId" placeholder="请选择理论讲师">
            <a-select-option
              v-for="item in lectors"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="实操讲师">
          <a-select v-model="modalForm.trainingId" placeholder="请选择实操讲师">
            <a-select-option
              v-for="item in trainings"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="带训师傅">
          <a-select
            v-model="modalForm.visitiservicerId"
            placeholder="请选择带训师傅"
          >
            <a-select-option
              v-for="item in visitisers"
              :key="item.id"
              :value="item.id"
            >
              {{ item.realName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            class="submit-btn"
            type="primary"
            :loading="modalLoading"
            @click="handleSubmit"
          >
            保存
          </a-button>
          <a-button class="cancel-btn" @click="handleCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let apprenticeId = ''
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      info: null,
      form: {
        id: '',
        avatar: '',
        jobNumber: '',
        contract: '',
        areaId: undefined,
        level: undefined
      },
      rules: {
        // 绑定地区
        jobNumber: [
          { required: true, message: '请输入师傅工号', trigger: 'blur' }
        ],
        trainNumber: [
          { required: true, message: '请输入培训合同编号', trigger: 'blur' }
        ],
        contract: [
          { required: true, message: '请上传合同', trigger: 'change' }
        ],
        avatar: [
          { required: true, message: '请上传师傅头像', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择所属地区', trigger: 'change' }
        ],
        level: [
          { required: true, message: '请选择师傅星级', trigger: 'change' }
        ]
      },
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      cascaderAreaOptions: [],
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      avatarLoading: false,
      submitLoading: false,
      modalVisible: false,
      modalLoading: false,
      modalForm: {
        id: undefined,
        lectorId: undefined,
        trainingId: undefined,
        visitiservicerId: undefined
      },
      lectors: [], // 理论讲师
      trainings: [], // 实操讲师
      visitisers: [], // 带训师傅
      starData: []
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    apprenticeId = query.id
    this.form.id = apprenticeId
    this.modalForm.id = apprenticeId
    this.getApprenticeTraining()
    this.getArea()
    this.getStarData()
  },
  methods: {
    getApprenticeTraining() {
      // 获取学徒培训信息
      const data = {
        id: apprenticeId
      }
      this.$axios.getApprenticeTraining(data).then((res) => {
        const d = res.data.data
        this.info = d
        this.subTitle = `${d.realName}（${d.studentNumber}）`
      })
    },
    openModal() {
      // 编辑讲师/带训
      this.modalVisible = true
      this.getLectors()
      this.getTrainings()
      this.getVisitisers()
    },
    getLectors() {
      // 获取学徒理论讲师列表
      this.$axios.getLectors().then((res) => {
        this.lectors = res.data.data
      })
    },
    getTrainings() {
      // 获取实操讲师列表
      this.$axios.getTrainings().then((res) => {
        this.trainings = res.data.data
      })
    },
    getVisitisers() {
      // 获取带训师傅列表
      this.$axios.getVisitisers().then((res) => {
        this.visitisers = res.data.data
      })
    },
    handleCancel() {
      // 取消编辑讲师/带训
      this.modalVisible = false
      this.modalLoading = false
      this.$refs.modalRuleForm.resetFields()
    },
    handleSubmit() {
      // 提交编辑讲师/带训
      this.$refs.modalRuleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true
          this.$axios.changeApprenticeMaster(this.modalForm).then(() => {
            this.$message.success('操作成功')
            this.handleCancel()
            this.getApprenticeTraining()
          })
        }
      })
    },
    async getArea() {
      // 获取区域列表
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
    },
    getStarData() {
      // 获取师傅星级源数据
      const data = {
        userType: 'servicer'
      }
      this.$axios.getStarData(data).then((res) => {
        this.starData = res.data.data
      })
    },
    handleAvatarChange(info) {
      // 头像上传
      if (info.file.status === 'uploading') {
        if (this.form.avatar) {
          this.form.avatar = ''
        }
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.avatar = this.qiniuData.host + info.file.response.key
        this.avatarLoading = false
      }
    },
    async beforeAvatarUpload(file) {
      // 头像上传检验
      await this.$examineImgWH(file)
    },
    handleContractChange(info) {
      // 合同上传
      if (info.file.status === 'done') {
        this.form.contract = this.qiniuData.host + info.file.response.key
      }
    },
    onSubmit() {
      // 下一步/提交
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          const data = JSON.parse(JSON.stringify(this.form))
          data.areaId = data.areaId[data.areaId.length - 1]
          this.$axios.apprenticeTurnMaster(data).then(() => {
            this.submitLoading = false
            this.$message.success('操作成功')
            setTimeout(() => {
              this.$router.go(-1)
            }, 500)
          })
        }
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.content-item-header {
  margin-bottom: 24px;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form .item {
  flex: 1;
}

.form .ant-divider {
  height: 208px;
}

.trun-submit-btn {
  margin-left: calc(50% - 44px);
}

.submit-btn {
  margin: 0 12px 0 7em;
}

.avatar {
  width: 88px;
  height: 88px;
  display: block;
  border-radius: 0;
}
</style>
