import { render, staticRenderFns } from "./apprentice-turn.vue?vue&type=template&id=492adb40&scoped=true&"
import script from "./apprentice-turn.vue?vue&type=script&lang=js&"
export * from "./apprentice-turn.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/info-page.css?vue&type=style&index=0&id=492adb40&scoped=true&lang=css&"
import style1 from "./apprentice-turn.vue?vue&type=style&index=1&id=492adb40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492adb40",
  null
  
)

export default component.exports